<template>
  <div>
    <h1 class="text-2xl mb-6">Top 10 Urls</h1>
    <div v-if="noData">
      Merci de remplir le formulaire afin d'obtenir les données.
    </div>
    <v-card class="pt-7 pb-3 mb-3" v-for="(site, index) in getTop10">
      <v-card-title class="text-h5 ml-3 mb-3 font-weight-bold">
        {{ getDomain(Object.keys(site)[index]) }}
      </v-card-title>
      <v-data-table
        class="elevation-1 ml-6 mr-6"
        :headers="headers"
        :items="itemsTop10(site)"
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Top10",
  data() {
    return {
      headers: [
        { text: "URL", value: "url" },
        { text: "Keyword", value: "Keyword" },
        { text: "Keyword Difficulty", value: "Keyword Difficulty" },
        { text: "Keyword Intents", value: "Keyword Intents" },
        { text: "Position", value: "Position" },
        { text: "Search Volume", value: "Search Volume" },
      ],
    };
  },
  methods: {
    getDomain(urlString) {
      let url = new URL(urlString);
      return url.hostname;
    },
    itemsTop10(site) {
      if (this.getData === undefined) {
        return [];
      } else {
        let list = [];
        for (let i = 0; i < Object.keys(site).length; i++) {
          let obj = site[Object.keys(site)[i]][0];
          list.push(obj);
        }
        return list;
      }
    },
  },
  computed: {
    noData() {
      if (
        this.getData.Site_positions === undefined ||
        !Object.keys(this.getData.Site_top10_urls).length === 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    getData() {
      return this.$store.getters["seo/getData"];
    },
    getTop10() {
      if (this.getData === undefined) {
        return [];
      } else {
        return Object.keys(this.getData)
          .filter((key) => key.toLowerCase().includes("top10"))
          .map((key) => this.getData[key]);
      }
    },
  },
};
</script>

<style scoped></style>
